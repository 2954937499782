import { MDXProvider } from '@mdx-js/react'
import React from 'react'
import {
  H1,
  H2,
  H3,
  PM,
  GlobalWidthLimiter,
  BlogPostBody,
} from '@src/style/mdxStyle'
import styled, { css } from 'styled-components'

export const WidthWrapped = ({ className, children }) => (
  <GlobalWidthLimiter className={className}>
    <BlogPostBody>{children}</BlogPostBody>
  </GlobalWidthLimiter>
)

export const MDXLayout = ({ children }) => (
  <MDXProvider
    components={{
      // Map HTML element tag to React component
      h1: props => (
        <WidthWrapped>
          <H1 {...props} />
        </WidthWrapped>
      ),
      h2: props => (
        <WidthWrapped>
          <H2 {...props} />
        </WidthWrapped>
      ),
      h3: props => (
        <WidthWrapped>
          <H3 {...props} />
        </WidthWrapped>
      ),
      p: props => (
        <WidthWrapped>
          <PM {...props} />
        </WidthWrapped>
      ),
      ul: styled.ul`
        padding: 1rem;
      `,
      li: styled.li`
        ${props => props.theme.typography.paragraph.M}
      `,
    }}
  >
    {children}
  </MDXProvider>
)
