import types from './actionTypes'

import api from '@src/api'

export const applyMiddleware = dispatch => async action => {
  console.log('applyMiddleware', action)
  switch(action.type) {
    case types.SIDE_LOAD_DRIVE: {
      try {
        const drive = await api.drive()
        dispatch({ type: types.LOAD_DRIVE_SUCCESS, payload: drive})
      } catch (e) {
        dispatch({ type: types.LOAD_DRIVE_FAILURE })
      }
    }
    default: dispatch(action)
  }
}