import types from './actionTypes'

export const useActions = (state, dispatch) => ({
  loadDriveData: (force = false) => {
    if (force || !state.drive) {
      console.log('loadDriveData')
      dispatch({ type: types.LOADING })
      dispatch({ type: types.SIDE_LOAD_DRIVE })
    }
  },
  setActiveMenu: (activeMenu) => {
    dispatch({ type: types.SET_ACTIVE_MENU, payload: activeMenu})
  }
})
