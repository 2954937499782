import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { WidthWrapped } from '@components/common/MDXLayout'

const _InlineWrapper = styled(WidthWrapped)`
  padding: 2rem 0 1rem 0;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.01);
  }
`

const _ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
`

export default ({ image, link, alt }) => {
  const img = image.childImageSharp ? (
    <Img alt={alt} fluid={image.childImageSharp.fluid} />
  ) : (
    <_ImgWrapper>
      <img alt={alt} src={image} />
    </_ImgWrapper>
  )

  return (
    <_InlineWrapper>
      {link ? (
        <a href={link} target='_blank'>
          {img}
        </a>
      ) : (
        img
      )}
    </_InlineWrapper>
  )
}
