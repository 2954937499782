import types from './actionTypes'

const initialState = {}

const reducer = (state = initialState, action) => {
  console.log('reducer', action)
  switch (action.type) {
    case types.LOADING:
      return {
        ...state,
        loading: true,
      }
    case types.LOAD_DRIVE_SUCCESS:
      return {
        ...state,
        drive: action.payload,
        loading: false,
      }
    case types.SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload,
      }
    default:
      return state
  }
}

export { initialState, reducer }
