import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
const _Gallery = styled.div`
  width: auto;
  height: auto;
  max-height: 600px;
  background: black;
`

const _PlainImage = styled.div`
  display: flex;
  justify-content: center;

  img {
    max-height: 100%;
  }
`

const _Img = styled(Img)`
  max-height: 100%;
`

const FullWidthImage = ({ image }) => {
  return (
    <>
      {image.childImageSharp ? (
        <_Img
          imgStyle={{ objectFit: 'contain', maxHeight: '600px' }}
          fluid={image.childImageSharp.fluid}
        />
      ) : (
        <_PlainImage>
          <img style={{ maxHeight: '600px' }} src={image} />
        </_PlainImage>
      )}
    </>
  )
}

export default ({ type, images }) => {
  return (
    <_Gallery>
      <FullWidthImage image={images[0]} />
    </_Gallery>
  )
}
