import React from 'react'
import styled from 'styled-components'
import { device, core } from '@src/style'

export const GlobalWidthLimiter = styled.div`
  position: relative;
  width: auto !important;
  margin: 0 auto;
  ${props => props.theme.widthLimiter}
`
export const BlogPostBody = styled.div`
  width: auto !important;
  margin: 0 auto 0 auto;
  ${props => props.theme.blogWidthLimiter}

  display: flex;
  flex-direction: column;
`

export const H1 = styled(core.H1)`
  padding-top: 3rem;
  color: ${props => props.theme.textColor};
`
export const H2 = styled(core.H2)`
  padding-top: 2rem;
  color: ${props => props.theme.textColor};
`
export const H3 = styled(core.H3)`
  padding-top: 1rem;
  color: ${props => props.theme.textColor};
`
export const PM = styled(core.PM)`
  color: ${props => props.theme.textColor};
`
export const PS = styled(core.PS)`
  color: ${props => props.theme.textColor};
`
