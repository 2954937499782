import { device } from '@src/style'

const black = {
  background: '#101010',
  text: '#95a5a6',
  menuBackground: '#000',
  menuText: '#888',
  menuTextHover: '#fff',
  borders: '#888'
}

const outrun0 = {
  synth1: '#1c0658',
  synth2: '#5c2686',
  synth3: '#ff1690',
  synth4: '#f4d676',
  synth5: '#36cdc4',
}

const outrun1 = {
  synth1: '#001256', //deep background
  synth2: '#c2115d', //red text
  synth3: '#e85663', //pink hover
  synth4: '#fbe232', //unused yellow
  synth5: '#0064ad', //cyan borders
}

const outrun2 = {
  synth1: '#01102c', //deep background
  synth2: '#6b1946', //red text
  synth3: '#f82370', //pink hover
  synth4: '#002754', //unused yellow
  synth5: '#314274', //cyan borders
}

const colors = black

export default {
  colors: colors,
  textColor: colors.text,
  body: {
    background: colors.background,
  },
  widthLimiter: `
    @media ${device.mobileS} {
      min-width: 280px;
      max-width: 280px;
    }
    @media ${device.mobileM} {
      min-width: 360px;
      max-width: 360px;
    }
    @media ${device.mobileL} {
      min-width: 360px;
      max-width: 360px;
    }
    @media ${device.tablet} {
      min-width: 680px;
      max-width: 680px;
    }
    @media ${device.laptop} {
      min-width: 900px;
      max-width: 900px;
    }
    @media ${device.laptopL} {
      min-width: 1300px;
      max-width: 1300px;
    }
    @media ${device.desktop} {
      min-width: 2000px;
      max-width: 2000px;
    }
  `,
  blogWidthLimiter: `
    min-width: 100%;
    max-width: 100%;

    @media ${device.tablet} {
      min-width: 680px;
      max-width: 680px;
    }
    @media ${device.laptop} {
      min-width: 800px;
      max-width: 800px;
    }
  `,
}
