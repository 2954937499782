import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.body.background};
    line-height: 100%;
  }
  a {
    color: white;
    text-decoration: none;
  }
  ul {
    margin: 0 auto;
  }
`
