import axios from 'axios'
const PROD_BASE_URL =
  'https://us-central1-averin-photo-site.cloudfunctions.net/api/'
const DEV_BASE_URL = 'http://localhost:5001/averin-photo-site/us-central1/api/'

const client = axios.create({
  baseURL: process.env.NODE_ENV == 'production' ? PROD_BASE_URL : PROD_BASE_URL,
})

const _drive = async () => {
  const response = await client.get(`/drive`)
  if (response.status == 200) {
    return response.data
  }
  console.log(response)
  return null
}

export default {
  drive: _drive,
}
