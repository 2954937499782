/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require('react')

require('typeface-manrope')

const theme = require('@src/style/theme').default
const { IntlProvider } = require('react-intl')
const { ThemeProvider } = require('styled-components')
const { StoreProvider } = require('@src/store/store')
const { GlobalStyle } = require('@src/style/globalStyle')

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StoreProvider>{element}</StoreProvider>
    </ThemeProvider>
  )
}
